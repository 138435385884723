'use strict';

module.exports = function () {
    $('form.campaign-interest-signup').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this);
        var $parent = $form.parent();
        var url = $form.attr('action');
        var method = $form.attr('method');
        $.spinner().start();
        $('.btn-email').prop('disabled', true);

        $.ajax({
            url: url,
            type: method,
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.success) {
                    $parent.find('form .form-group.email').hide();
                    $parent.find('.js-campaign-signup-invalid').text('').hide();
                    $parent.find('form .form__email-signup').alert({
                        content: data.msg,
                        theme: 'success',
                        placement: 'inline',
                        dismissible: false
                    });

                    if ('dtmLayer' in data && 'digitalData' in window) {
                        if (data.dtmLayer.page && data.dtmLayer.page.form &&
                            window.digitalData.page && window.digitalData.page.form) {
                            window.digitalData.page.form.registrationType = data.dtmLayer.page.form.registrationType;
                        }

                        if (data.dtmLayer.user) {
                            window.digitalData.user = data.dtmLayer.user;
                        }
                    }
                } else if (data.msg) {
                    $parent.find('.js-campaign-signup-invalid').text(data.msg).show();
                }
            },
            complete: function () {
                $.spinner().stop();
                $('.btn-email').prop('disabled', false);
            }
        });
    });
};
